<template>
  <!--begin::Card-->
  <div>
    QR Code <br />
    <div class="row">

        <div class="col-lg-4">
          <h4 class="mb-10 font-weight-bold text-dark">
              <small
                >Your website QR Code.</small
              >
            </h4>

            <div >
                <img :src="homePageURL" alt="my alt" />
            </div>
         </div>   
         <div class="col-lg-4">
          <h4 class="mb-10 font-weight-bold text-dark">
              <small
                >Your Menue Page QR Code.</small
              >
            </h4>

            <div >
                <img :src="MenuPageURL" alt="my alt" />
            </div>
         </div> 
            <div class="col-lg-4">
          <h4 class="mb-10 font-weight-bold text-dark">
              <small
                >Your Table Page QR Code.</small
              >
            </h4>

            <div >
                <img :src="tablePageURL" alt="my alt" />
            </div>
         </div> 
          
        
</div>
  </div>
  <!--end::Card-->
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
.pac-target-input {
  padding: 10px;
  width: 20%;
}
.btn {
  margin-left: 20px;
  padding: 10px 20px;
  background-color: greenyellow;
}
</style>
<script>
import { mapGetters } from "vuex";

export default {
  name: "QRCode",
  data() {
    return {

    };
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),

    homePageURL ()
    {
      console.log('encodeURIComponent',this.currentUserPersonalInfo)
      return "https://api.qrserver.com/v1/create-qr-code/?size=300x300&data=" + encodeURIComponent(this.currentUserPersonalInfo.store_url);
    },
    MenuPageURL ()
    {
      console.log('MenuPageURL',this.currentUserPersonalInfo.store_url+'/menu')
      return "https://api.qrserver.com/v1/create-qr-code/?size=300x300&data=" + encodeURIComponent(this.currentUserPersonalInfo.store_url+'/menue');
    },
     tablePageURL ()
    {
      console.log('MenuPageURL',this.currentUserPersonalInfo.store_url+'/book-table')
      return "https://api.qrserver.com/v1/create-qr-code/?size=300x300&data=" + encodeURIComponent(this.currentUserPersonalInfo.store_url+'/book-table');
    },
    
 
  },

};
</script>
